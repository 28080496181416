<template>
    <v-footer
            app
            class="elevation-12"
            :color="$route.query.insurance==='MPU' ? 'blue' : 'primary'"
            height="66"
    >


                    <div class="caption pr-md-12">
                        &copy;{{ new Date().getFullYear() }}. All rights reserved
                    </div>

          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"> </v-spacer>
      <v-btn
          class="mr-10"
          text
          :x-small="$vuetify.breakpoint.smAndDown"
          aria-label="About"
          to="/privacy"
      >
        Datenschutz
      </v-btn>
                <v-btn
                        class="mr-10"
                        text
                        :x-small="$vuetify.breakpoint.smAndDown"
                        aria-label="About"
                        to="/about"
                >
                    Impressum
                </v-btn>

    </v-footer>

</template>

<script>
    export default {
        name: "CoreFooter",
        data: () => ({

        }),
    }
</script>

<style scoped>

</style>
